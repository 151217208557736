import {
  computed,
  inject,
  ref,
  Teleport,
  watch
} from "vue"

import {
  t,
  plural
} from "@lingui/macro"

import { snake } from "case"
import { useForm, useField } from "vee-validate"

import debounce from "debounce"
import http from "~/http"

import {
  usePaginate
} from "~/hooks"

import Countdown from "vue-countdown"
import QRCode from "vue-qrcode"
import Clipboard from "../Clipboard"

export default {
  setup() {
    const accountRef = inject("accountRef")
    const wallets = inject("wallets")

    const convertedRef = ref(null)
    const qrcodeRef = ref(null)

    const {
      currentPage,
      next
    } = usePaginate()

    const {
      meta,
      handleSubmit,
      isSubmitting
    } = useForm()

    const {
      value: amountRef,
      errorMessage: amountError
    } = useField("amount", value => {
      return value >= 50 ||
        t`Should be 50 USD/EUR minimum.`
    })

    const convertAmount = debounce(async amount => {
      const { data } = await http.get("wallets", {
        params: {
          currency: accountRef
            .value
            .currency,
          amount: amountRef.value
        }
      })
      convertedRef.value = data[
        snake`amountBtc`
      ]
    }, 250)

    const onSubmit = handleSubmit(
      async values => {
        if (currentPage.value === 1) {
          const {
            name: accountName,
            currency
          } = accountRef.value

          await http.post("payments", {
            accountName,
            currency,
            paymentMethod: "BTC",
            ...values
          })

          const nodes =
            document.querySelectorAll("input, select")

          for (let i = 0; i < nodes.length; i ++) {
            nodes[i].disabled = true
          }
        }
        next()
      }
    )

    const isDisabled = computed(
      () => ! (amountRef.value && convertedRef.value) || isSubmitting.value
    )

    const URI = computed(
      () => `bitcoin:${wallets.btc}?amount=${convertedRef.value}`
    )

    watch([
      accountRef,
      amountRef
    ], convertAmount)

    return {
      accountRef,
      amountError,
      amountRef,
      URI,
      convertedRef,
      currentPage,
      isDisabled,
      meta,
      onSubmit,
      wallets
    }
  },
  render() {
    const {
      accountRef,
      amountError,
      amountRef,
      URI,
      convertedRef,
      currentPage,
      isDisabled,
      meta,
      onSubmit,
      wallets
    } = this

    return wallets.btc ? (
      <Fragment>
        {(currentPage === 1) && (
          <Fragment>
            <div class="mt-n1">
              <label>
                {t`Amount in`} <u>{accountRef.currency}</u>:
              </label>
              <input
                v-model={this.amountRef}
                placeholder=""
                type="number"
              />
              {amountError && (
                <div class="mt-2 text-danger">
                  {amountError}
                </div>
              )}
            </div>
            {(convertedRef && meta.valid) && (
              <center>
                <div class="bi bi-arrow-down fs-1 text-success my-3">
                  &nbsp;
                </div>
                <div class="border bg-white rounded p-4">
                  <span class="fs-4">
                    {convertedRef}
                  </span>
                  <br/>
                  <span class="text-muted">
                    BTC
                  </span>
                </div>
              </center>
            )}
          </Fragment>
        )}
        {(currentPage === 2) && (
          <Fragment>
            <div class="mb-4 mt-3">
              <center>
                <div class="fs-5 text-muted">
                  {t`Time Remaining to Pay`}
                </div>
                <Countdown time={900_000}>
                  {({
                    minutes,
                    seconds
                  }) => (
                    <div class="fs-3">
                      {String(minutes).padStart(2, "0")}:{String(seconds).padStart(2, "0")}
                    </div>
                  )}
                </Countdown>
              </center>
            </div>
            <div class="border bg-white rounded px-3 py-4">
              <center>
                <div class="text-muted">
                  {t`Amount`}
                </div>
                <Clipboard>
                  {convertedRef}
                </Clipboard>
                &nbsp;BTC
                <div class="text-muted mt-2">
                  {t`Address`}
                </div>
                <Clipboard>
                  {wallets.btc}
                </Clipboard>
              </center>
              <div style="height: 6px">
                &nbsp;
              </div>
              <div class="mb-1 mt-4">
                <center>
                  <div class="border d-inline-block rounded" style="line-height: 0">
                    <QRCode value={URI} />
                  </div>
                </center>
              </div>
            </div>
            <div class="mt-3">
              <center>
                <a href={URI}>
                  {t`Open in Wallet`}
                </a>
              </center>
            </div>
          </Fragment>
        )}
        {(currentPage === 3) && (
          <div class="fs-4 mt-3 mb-4 mx-3">
            <center>
              {t`Thank you!`}
              <br/>
              <br/>
              {t`We'll check your transaction as soon as possible.`}
            </center>
          </div>
        )}
        <Teleport to=".modal-footer">
          {(currentPage <= 2) && (
            <button class="btn btn-primary" disabled={!meta.valid || isDisabled} onClick={onSubmit}>
              {currentPage === 1
                ? t`Pay`
                : t`I have Paid`}
            </button>
          )}
        </Teleport>
      </Fragment>
    ) : (
      <div class="alert-danger alert px-3 py-2 mb-0">
        {t`This method of payment is not activated for your account. Please contact the manager.`}
      </div>
    )
  }
}
