import {
  ref,
  watchEffect
} from "vue"

import {
  t,
  plural
} from "@lingui/macro"

import http from "~/http"

import PaymentModal from "~/components/PaymentModal"
import Badge from "~/components/Badge"

import {
  usePaginate,
  useModal
} from "~/hooks"

const PaymentList = {
  setup() {
    const paymentsRef = ref(null)

    const {
      currentPage,
      next,
      prev
    } = usePaginate()

    watchEffect(async () => {
      const { data } = await http.get("payments", {
        params: {
          page: currentPage.value
        }
      })

      paymentsRef.value = [
        ...data
      ]
    })

    return {
      currentPage,
      next,
      paymentsRef,
      prev
    }
  },
  render() {
    const {
      currentPage,
      next,
      paymentsRef,
      prev
    } = this

    return (
      <Fragment>
        {paymentsRef ? (
          <Fragment>
            <div class="table-responsive">
              <table class="table table-hover mb-0">
                <thead>
                  <tr>
                    <th style="width: 10%">&nbsp;</th>
                    <th style="width: 22%">{t`Date`}</th>
                    <th>{t`Account`}</th>
                    <th style="width: 20%">{t`Status`}</th>
                    <th style="width: 20%">{t`Amount`}</th>
                  </tr>
                </thead>
                <tbody>
                  {paymentsRef?.map(payment => (
                    <tr>
                      <td>&nbsp;</td>
                      <td>{payment.createdAt}</td>
                      <td>{payment.accountName}</td>
                      <td><Badge value={payment.status} /></td>
                      <td>
                        {/^-/.test(payment.amount) ? (
                          <span class="text-danger">
                            {payment.amount}
                          </span>
                        ) : (
                          <span class="text-success">
                            {payment.amount}
                          </span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {(paymentsRef?.length <= 0) && (
                <div class="bg-light border rounded py-2 mt-4 text-muted">
                  <center>
                    {t`There's nothing on this page.`}
                  </center>
                </div>
              )}
            </div>
            <Pagination
              currentPage={currentPage}
              next={next}
              prev={prev}
            />
          </Fragment>
        ) : (
          <Spinner/>
        )}
      </Fragment>
    )
  }
}

export default {
  setup() {
    const { showModal } = useModal(<PaymentModal/>)

    return {
      showModal
    }
  },
  render() {
    const {
      showModal
    } = this

    return (
      <Fragment>
        <h2 class="mb-4 px-4">
          {t`Payments`}
          <button
            class="btn-primary btn float-end mt-n1 mt-lg-0"
            onClick={() => {
              showModal()
            }}
          >
            {t`Add Funds`}
          </button>
        </h2>
        <div class="bg-white border rounded l-content p-4">
          <PaymentList/>
        </div>
      </Fragment>
    )
  }
}
