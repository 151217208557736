import {
  computed,
  inject,
  Teleport
} from "vue"

import {
  t,
  plural
} from "@lingui/macro"

import {
  useField,
  useForm,
  useSubmitForm
} from "vee-validate"

import http from "~/http"

import {
  usePaginate
} from "~/hooks"

export default {
  setup() {
    const accountRef = inject("accountRef")

    const {
      currentPage,
      next
    } = usePaginate()

    const {
      meta,
      isSubmitting
    } = useForm()

    const {
      value: amountRef,
      errorMessage: amountError
    } = useField("amount", value => {
      return value >= 500 ||
        t`Should be 500 RUB minimum.`
    })

    const handleSubmit = useSubmitForm(
      async values => {
        const {
          name: accountName,
          currency
        } = accountRef.value

        const { data } = await http.post("payments", {
          accountName,
          currency: "RUB",
          paymentMethod: "paymaster",
          ...values
        })

        next(); window.open(data)
      }
    )

    const isDisabled = computed(
      () => !amountRef.value || isSubmitting.value
    )

    return {
      amountError,
      amountRef,
      currentPage,
      handleSubmit,
      isDisabled,
      meta
    }
  },
  render() {
    const {
      amountError,
      amountRef,
      currentPage,
      handleSubmit,
      isDisabled,
      meta
    } = this

    return (
      <Fragment>
        {currentPage === 1 && (
          <div class="mt-n1">
            <label>
              {t`Amount in`} <u>RUB</u>:
            </label>
            <input
              v-model={this.amountRef}
              placeholder=""
              type="number"
            />
            {amountError && (
              <div class="mt-2 text-danger">
                {amountError}
              </div>
            )}
          </div>
        )}
        {currentPage === 2 && (
          <div class="fs-4 mt-3 mb-4 mx-3">
            <center>
              {t`Thank you!`}
              <br/>
              <br/>
              {t`Payment gateway window is being opened now...`}
            </center>
          </div>
        )}
        <Teleport to=".modal-footer">
          {(currentPage === 1) && (
            <button class="btn btn-primary" disabled={!meta.valid || isDisabled} onClick={handleSubmit}>
              {t`Pay`}
            </button>
          )}
        </Teleport>
      </Fragment>
    )
  }
}
