import { create } from "axios"

import {
  API_ENDPOINT
} from "./constants"

export default create({
  baseURL: API_ENDPOINT,
  headers: { Authorization: localStorage.accessToken },
  timeout: 5e3
})
