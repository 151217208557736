import {
  computed,
  onBeforeUnmount,
  watchEffect
} from "vue"

import {
  t,
  plural
} from "@lingui/macro"

import EquipmentModal from "~/components/EquipmentModal"
import Badge from "~/components/Badge"

import {
  useEquipmentStore
} from "~/store"

import {
  usePaginate,
  useModal
} from "~/hooks"

const EquipmentList = {
  setup() {
    const equipmentStore = useEquipmentStore()
    const equipmentRef = computed(
      () => equipmentStore.equipment
    )

    const { showModal } = useModal(
      <EquipmentModal
        isEditing
      />
    )

    const {
      currentPage,
      next,
      prev
    } = usePaginate()

    watchEffect(() => {
      equipmentStore.fetch({
        page: currentPage.value
      })
    })

    equipmentStore.$onAction(action => {
      setTimeout(() => {
        switch (action.name) {
          case "create":
          case "update":
          case "delete":
            equipmentStore.fetch({
              page: currentPage.value
            })
        }
      }, 1_000)
    })

    onBeforeUnmount(() => equipmentStore.$reset())

    return {
      currentPage,
      next,
      equipmentRef,
      showModal,
      prev
    }
  },
  render() {
    const {
      currentPage,
      next,
      equipmentRef,
      showModal,
      prev
    } = this

    return (
      <Fragment>
        {equipmentRef ? (
          <Fragment>
            <div class="table-responsive">
              <table class="table table-hover mb-0">
                <thead>
                  <tr>
                    <th>{t`ID`}</th>
                    <th>{t`IP Address`}</th>
                    <th>{t`Status`}</th>
                    <th>{t`Actions`}</th>
                  </tr>
                </thead>
                <tbody>
                  {equipmentRef?.map(equipment => (
                    <tr
                      class="pointer"
                      onClick={() => {
                        equipment.status !== "deleting"
                          && showModal(equipment)
                      }}
                    >
                      <td>{equipment.id}</td>
                      <td>{equipment.ip}</td>
                      <td><Badge value={equipment.status} /></td>
                      <td>
                        {(equipment.status !== "deleting") && (
                          <a href="#">
                            {t`Edit`}
                          </a>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {(equipmentRef?.length <= 0) && (
                <div class="bg-light border rounded py-2 mt-4 text-muted">
                  <center>
                    {t`There's nothing on this page.`}
                  </center>
                </div>
              )}
            </div>
            <Pagination
              currentPage={currentPage}
              next={next}
              prev={prev}
            />
          </Fragment>
        ) : (
          <Spinner/>
        )}
      </Fragment>
    )
  }
}

export default {
  setup() {
    const { showModal } = useModal(<EquipmentModal/>)

    return {
      showModal
    }
  },
  render() {
    const {
      showModal
    } = this

    return (
      <Fragment>
        <h2 class="mb-4 px-4">
          {t`Equipment`}
          <button
            class="btn-primary btn float-end mt-n1 mt-lg-0"
            onClick={() => {
              showModal()
            }}
          >
            {t`Add New`}
          </button>
        </h2>
        <div class="bg-white border rounded l-content p-4">
          <EquipmentList/>
        </div>
      </Fragment>
    )
  }
}
