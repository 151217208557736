import {
  Suspense
} from "vue"

import {
  t,
  plural
} from "@lingui/macro"

import Highstock from "highstock"
import http from "~/http"

Highstock.setOptions({
  chart: {
    spacing: [0, 0, 0, 0],
    type: null,
    zoomType: "x"
  },
  xAxis: {
    type: "datetime"
  },
  yAxis: {
    title: {
      text: null
    }
  },
  rangeSelector: {
    buttons: [
      { text: "06h", count: "06", type: "hour" },
      { text: "12h", count: "12", type: "hour" },
      { text: "24h", count: "24", type: "hour" },
      { text: "all", type: "all" }
    ]
  },
  tooltip: {
    valueDecimals: 0
  },
  credits: {
    enabled: false
  }
})

const ChartList = {
  async setup() {
    const charts =
      await Promise.all([
        http.get("statistics/calls"),
        http.get("statistics/attempts"),
        http.get("statistics/payables")
      ])

    return {
      charts
    }
  },
  render() {
    return (
      <Fragment>
        <div id="chart-0" />
        <hr class="my-4" />
        <div id="chart-1" />
        <hr class="my-4" />
        <div id="chart-2" />
      </Fragment>
    )
  },
  mounted() {
    const {
      charts
    } = this

    const convertToChartSeries = data =>
      data[0].Series.map(slice => {
        const {
          values,
          tags
        } = slice

        return {
          data: values.map(
            value => ([
              new Date(value[0]).getTime(),
              value[1]
            ])
          ),
          name: tags.client,
          stacking: "normal"
        }
      })

    charts.forEach((chart, idx) => {
      Highstock.stockChart(`chart-${idx}`, {
        chart: {
          type: idx === 2
            ? "column"
            : "area"
        },
        title: {
          text: [
            t`Calls`,
            t`Attempts`,
            t`Usage`
          ][idx]
        },
        subtitle: {
          text: idx === 2
            ? t`in USD`
            : null
        },
        series: convertToChartSeries(chart.data)
      })
    })
  }
}

export default {
  render() {
    return (
      <Fragment>
        <h2 class="mb-4 px-4">
          {t`Statistics`}
          <div class="fs-5 text-muted fw-normal mt-2">
            {t`All data is provided at UTC+0 time zone.`}
          </div>
        </h2>
        <div class="bg-white border rounded p-4 l-content">
          <Suspense v-slots={{ fallback: () => <Spinner/> }}>
            <ChartList/>
          </Suspense>
        </div>
      </Fragment>
    )
  }
}
