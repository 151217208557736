import {
  ref,
  shallowRef
} from "vue"

import {
  t,
  plural
} from "@lingui/macro"

import assets from "~/assets"

// const {
//   VUE_APP_SUPPORTS_REGISTER
// } = process.env

export default {
  render() {
    return (
      <div class="d-flex justify-content-center w-100 h-100 align-items-center l-auth">
        <div class="d-inline-block">
          {assets.logotype && (
            <center style="margin-top: -108px;">
              <img
                src={assets.logotype}
                style={`
                  display: inline-block;
                  margin-bottom: 48px;
                  height: 56px;
                `}
              />
            </center>
          )}
          <div class="bg-white border rounded p-4" style={{ width: 384 + "px" }}>
            <RouterView/>
          </div>
        </div>
      </div>
    )
  }
}

// {VUE_APP_SUPPORTS_REGISTER && (
//   <div class="mt-4">
//     <hr/>
//     {viewRef ? (
//       <a
//         href="#"
//         onClick={evt => {
//           this.ComponentRef = <LoginForm/>
//           this.viewRef = 0
//         }}
//       >
//         {t`Already have an account?`}
//       </a>
//     ) : (
//       <Fragment>
//         {t`Need an account?`}&nbsp;
//         <a
//           href="#"
//           onClick={evt => {
//             evt.preventDefault()
//             this.ComponentRef = <RegisterForm/>
//             this.viewRef = 1
//           }}
//         >
//           {t`Register`}
//         </a>
//       </Fragment>
//     )}
//   </div>
// )}
