import {
  computed
} from "vue"

import {
  t,
  plural
} from "@lingui/macro"

import PaymentModal from "~/components/PaymentModal"

import http from "~/http"
import { useStore } from "~/store"

import {
  useModal
} from "~/hooks"

const FormattedMoney = ({ value }) => (
  <div class="fs-4 fw-bold mt-1 mb-1 mb-sm-0">
    {value}
  </div>
)

export default {
  setup() {
    const store = useStore()

    const { showModal } = useModal(<PaymentModal/>)
    const accounts = computed(() => store.accounts)

    return {
      accounts,
      showModal,
      store
    }
  },
  render() {
    const {
      accounts,
      showModal,
      store
    } = this

    return (
      <Fragment>
        <h2 class="mb-4 px-4">
          {t`Accounts`}
        </h2>
        <div class="bg-white border rounded p-4 l-content">
          {accounts.sort((a, b) => (a.isBlocked - b.isBlocked)).map((account, idx) => (
            <Fragment>
              {account.isBlocked && (
                <div class="badge bg-danger mb-4">
                  {t`Disconnected`}
                </div>
              )}
              <div class="h4">
                {account.name}
              </div>
              <div class="row row-cols-1 row-cols-sm-3 mt-4">
                <div class="col">
                  <div class="text-muted">
                    {t`Current Balance`}
                    <a
                      class="text-primary float-end"
                      onClick={() => {
                        showModal(account)
                      }}
                      href="#"
                    >
                      {t`Add Funds`}
                    </a>
                  </div>
                  <FormattedMoney
                    value={account.balance}
                  />
                </div>
                <div class="col">
                  <div class="text-muted">
                    {t`Credit Limit`}
                  </div>
                  <FormattedMoney
                    value={account.credit}
                  />
                </div>
                <div class="col">
                  <div class="text-muted">
                    {t`Available to Use`}
                  </div>
                  <FormattedMoney
                    value={account.available}
                  />
                </div>
              </div>
              {idx !== accounts.length - 1 && (
                <hr class="my-4" />
              )}
            </Fragment>
          ))}
          {(accounts?.length <= 0) && (
            <div class="text-muted">
              <center>
                {t`Your account is being prepared now.`}
              </center>
            </div>
          )}
        </div>
      </Fragment>
    )
  }
}
