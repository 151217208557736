import {
  computed
} from "vue"

import {
  t,
  plural
} from "@lingui/macro"

import http from "~/http"

import {
  useField,
  useForm,
  useSubmitForm
} from "vee-validate"

export default {
  setup() {
    const {
      errors,
      isSubmitting
    } = useForm({
      initialValues: {
        subject: null,
        text: null
      }
    })

    const { value: subjectRef } = useField("subject")
    const { value: textRef } = useField("text")

    const handleSubmit = useSubmitForm(
      async (values, { setErrors, resetForm }) => {
        try {
          await http.post("support", values)
          resetForm()
        }
        catch (error) {
          setErrors([
            error
              .response
              .data
          ])
        }
      }
    )

    const isDisabled = computed(
      () => ! (subjectRef.value && textRef.value) || isSubmitting.value
    )

    return {
      errors,
      handleSubmit,
      isDisabled,
      subjectRef,
      textRef
    }
  },
  render() {
    const {
      errors,
      handleSubmit,
      isDisabled
    } = this

    return (
      <Fragment>
        <h2 class="mb-4 px-4">
          {t`Support`}
        </h2>
        <div class="bg-white border rounded p-4 l-content">
          <center>
            <h4>
              {t`Our support team is available on:`}
            </h4>
            <div class="mt-3">
              Telegram: <a href="//t.me/CallsSupportBot" target="_blank">Calls Support</a>
            </div>
          </center>
        </div>
      </Fragment>
    )
  }
}

/*
{errors[0] && (
  <div class="alert-danger py-2 alert px-3 mb-3">
    {errors[0]}
  </div>
)}
<form onSubmit={handleSubmit}>
  <div class="mb-2">
    <label>
      {t`Subject`}:
    </label>
    <input
      v-model={this.subjectRef}
      placeholder=""
      type="text"
    />
  </div>
  <div class="mb-4">
    <label>
      {t`Text`}:
    </label>
    <textarea
      v-model={this.textRef}
      rows="8"
    />
  </div>
  <button class="btn-primary btn" disabled={isDisabled}>
    {t`Send`}
  </button>
</form>
*/
