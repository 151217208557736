const logotypes = [
  require("./logotype-0.svg").default
]

const domains = [
  "my.telexchange.ee"
]

export default {
  logotype: logotypes[domains.indexOf(location.hostname)]
}
