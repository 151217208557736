import {
  ref
} from "vue"

import { useStore } from "~/store"

export function usePaginate(options = {}) {
  const {
    initialPage = 1
  } = options

  const currentPage = ref(initialPage)

  return {
    next: () => currentPage.value ++,
    prev: () => currentPage.value --,
    currentPage
  }
}


export function useModal(
  ComponentRef
) {
  const store = useStore()

  return {
    showModal: props => store.modal = (
      <ComponentRef
        onClose={() => {
          store.modal = null
        }}
        {...props}
      />
    ),
    hideModal: () => store.modal = null
  }
}
