import { createApp } from "vue"
import "./styles"

import Modal from "./components/Modal"
import Pagination from "./components/Pagination"
import Spinner from "./components/Spinner"

import Root from "./Root"
import router from "./router"
import store from "./store"
import "./i18n"

createApp(<Root/>)
  .component("Modal", Modal)
  .component("Pagination", Pagination)
  .component("Spinner", Spinner)
  .use(router)
  .use(store)
  .mount("#root")
