import { inject } from "vue"

import {
  t,
  plural
} from "@lingui/macro"

import {
  EUR,
  USD
} from "./requisites"

export default {
  setup() {
    const accountRef = inject("accountRef")

    return {
      accountRef
    }
  },
  render() {
    const {
      accountRef
    } = this

    const bank =
      accountRef.currency === "USD"
        ? USD
        : EUR

    return (
      <Fragment>
        <div class="mb-2">
          <div class="text-muted">
            {t`Beneficiary Name`}
          </div>
          TeleXchange System OU
        </div>
        <div class="mb-0">
          <div class="text-muted">
            {t`Beneficiary Address`}
          </div>
          Tuukri, 19-216, Tallinn, 10152, Estonia
        </div>
        <hr class="my-3" />
        {bank && (
          <Fragment>
            <div class="mb-2">
              <div class="text-muted">
                {t`Bank Name`}
              </div>
              {bank.name}
            </div>
            <div class="mb-2">
              <div class="text-muted">
                {t`Bank Address`}
              </div>
              {bank.address}
            </div>
            <div class="mb-2">
              <div class="text-muted">
                IBAN
              </div>
              {bank.iban}
            </div>
            {bank.correspondent && (
              <div class="mb-2">
                <div class="text-muted">
                  {t`Correspondent Bank`}
                </div>
                {bank.correspondent}
              </div>
            )}
            <div class="mb-0">
              <div class="text-muted">
                SWIFT
              </div>
              {bank.swift}
            </div>
            <hr class="my-3" />
          </Fragment>
        )}
        <div class="mb-2">
          <div class="text-muted">
            {t`Purpose of Payment`}
          </div>
          {accountRef.paymentId}
        </div>
        <div class="text-muted">
          {t`This payment identifier must be indicated in the payment description.`}
        </div>
      </Fragment>
    )
  }
}
