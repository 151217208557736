import {
  i18n
} from "@lingui/core"

const {
  VUE_APP_LOCALE
} = process.env

import en from "./locales/en"
import ru from "./locales/ru"

i18n.load("en", en)
i18n.load("ru", ru)

i18n.activate(VUE_APP_LOCALE || "en")
