import {
  computed
} from "vue"

import {
  t,
  plural
} from "@lingui/macro"

const {
  VUE_APP_SUPPORTS_EQUIPMENT,
  VUE_APP_SUPPORTS_SUPPORT
} = process.env

import {
  useStore
} from "~/store"

export default {
  setup() {
    const accounts = computed(() => useStore().accounts)

    const links = [
      { to: "/", name: t`Home` },
      { to: "/statistics", name: t`Statistics` },
      { to: "/payments", name: t`Payments` }
    ]

    const icons = [
      "house",
      "bar-chart",
      "credit-card"
    ]

    if (accounts.value?.length <= 0) {
      links[1].disabled = true
    }

    if (VUE_APP_SUPPORTS_EQUIPMENT) {
      links.splice(1, 0, {
        to: "/equipment", name: t`Equipment`
      })

      icons.splice(1, 0, "gear")
    }

    return {
      links,
      icons
    }
  },
  render() {
    const {
      links,
      icons
    } = this

    return (
      <ul class="nav flex-column pb-4 px-xl-0 px-4 nav-pills">
        {links.map(({ name, disabled, icon, ...props }, idx) => (
          <li class="nav-item">
            <RouterLink class={`nav-link ${disabled && "disabled"}`} {...props}>
              <i class={`bi bi-${icons[idx]}-fill d-inline me-2`} />
              {name}
            </RouterLink>
          </li>
        ))}
        {VUE_APP_SUPPORTS_SUPPORT && (
          <Fragment>
            <hr/>
            <li class="nav-item">
              <RouterLink class="nav-link" to={`/support`}>
                <i class="bi bi-question-circle-fill d-inline me-2" />
                {t`Support`}
              </RouterLink>
            </li>
          </Fragment>
        )}
      </ul>
    )
  }
}
