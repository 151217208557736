import {
  computed,
  provide,
  ref,
  shallowRef,
  unref,
  watch
} from "vue"

import {
  t,
  plural
} from "@lingui/macro"

import { useStore } from "~/store"

const {
  VUE_APP_SUPPORTS_BANK_TRANSFER,
  VUE_APP_SUPPORTS_CRYPTO,
  VUE_APP_SUPPORTS_PAYMASTER
} = process.env

let BankList
let CryptoWallet
let PayMaster

if (VUE_APP_SUPPORTS_BANK_TRANSFER) {
  BankList = require("./BankList").default
}

if (VUE_APP_SUPPORTS_CRYPTO) {
  CryptoWallet = require("./CryptoWallet").default
}

if (VUE_APP_SUPPORTS_PAYMASTER) {
  PayMaster = require("./PayMaster").default
}

export default {
  props: [
    "onClose"
  ],
  setup(_, { attrs }) {
    const store = useStore()

    const accounts = computed(() => store.accounts)
    const modal = computed(() => store.modal)
    const wallets = computed(() => store.wallets)

    const ComponentRef = shallowRef(null)
    const accountRef = ref(attrs)

    watch(accountRef, next => {
      !next && (ComponentRef.value = null)
    })

    provide("accountRef", accountRef)
    provide("wallets", unref(wallets))

    return {
      accounts,
      ComponentRef,
      accountRef
    }
  },
  render() {
    const {
      accounts,
      ComponentRef,
      accountRef
    } = this

    return (
      <Modal
        onClose={() => {
          this.onClose()
        }}
      >
        <div class="modal-body">
          <div class="mt-n1 mb-2">
            <label>
              {t`Account`}:
            </label>
            <select v-model={this.accountRef}>
              <option></option>
              {accounts.map(account => (
                <Fragment>
                  <option value={account}>
                    {account.name}
                  </option>
                </Fragment>
              ))}
            </select>
          </div>
          <div class="mb-0">
            <label>
              {t`Payment Method`}:
            </label>
            <select v-model={this.ComponentRef} disabled={Object.keys(accountRef).length === 0}>
              <option></option>
              {VUE_APP_SUPPORTS_BANK_TRANSFER && (
                <option value={<BankList/>}>
                  Bank Transfer
                </option>
              )}
              {VUE_APP_SUPPORTS_CRYPTO && (
                <option value={<CryptoWallet/>}>
                  BTC (Bitcoin)
                </option>
              )}
              {VUE_APP_SUPPORTS_PAYMASTER && (
                <option value={<PayMaster/>}>
                  PayMaster
                </option>
              )}
            </select>
          </div>
          {ComponentRef && (
            <div class="bg-light border-top mx-n3 mt-3 p-3 mb-n3">
              <ComponentRef/>
            </div>
          )}
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-secondary"
            onClick={() => {
              this.onClose()
            }}
          >
            {t`Close`}
          </button>
        </div>
      </Modal>
    )
  }
}
