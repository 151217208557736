export default {
  render() {
    const children =
      this.$slots.default()

    const {
      clipboard
    } = navigator

    return (
      <div
        class="d-inline-block text-break"
        onClick={() => {
          clipboard.writeText(children[0].children)
        }}
      >
        <i class="bi bi-files" />&nbsp;
        <span
          style={{
            borderBottom: "1px dotted #000",
            cursor: "pointer"
          }}
        >
          {children}
        </span>
      </div>
    )
  }
}
