import {
  onKeyDown
} from "vue-use"

export default {
  props: [
    "onClose"
  ],
  setup(props) {
    onKeyDown("Escape", () => {
      props.onClose()
    })
  },
  render() {
    const children =
      this.$slots.default()

    return (
      <div class="modal show">
        <div class="modal-dialog modal-dialog-scrollable">
          <div class="modal-content">
            {children}
          </div>
        </div>
        <div
          class="modal-backdrop"
          onClick={() => {
            this.onClose()
          }}
        />
      </div>
    )
  }
}
