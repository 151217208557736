export default {
  props: [
    "value"
  ],
  render() {
    const {
      value
    } = this.$props

    let appereance

    switch (value) {
      case "unverified":
      case "fail":
        appereance = "danger"
        break
      case "deleting":
        appereance = "warning"
        break
      default:
        appereance = "success"
    }

    return (
      <span class={`badge rounded-pill bg-${appereance}`}>
        {value}
      </span>
    )
  }
}
