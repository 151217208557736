import {
  Suspense
} from "vue"

import Root from "./Root"

export default {
  render() {
    return (
      <Suspense v-slots={{ fallback: () => <Spinner/> }}>
        <Root/>
      </Suspense>
    )
  }
}
