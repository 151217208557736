import {
  t,
  plural
} from "@lingui/macro"

export default {
  props: [
    "currentPage",
    "next",
    "prev"
  ],
  render() {
    const {
      currentPage,
      next,
      prev
    } = this.$props

    return (
      <ul class="pagination mb-0 mt-4">
        <li class={{ disabled: currentPage <= 1, "page-item": true }}>
          <button
            class="page-link"
            onClick={() => {
              prev()
            }}
          >
            {t`Prev`}
          </button>
        </li>
        <li class="page-item disabled">
          <span class="page-link">
            {currentPage}
          </span>
        </li>
        <li class="page-item">
          <button
            class="page-link"
            onClick={() => {
              next()
            }}
          >
            {t`Next`}
          </button>
        </li>
      </ul>
    )
  }
}
