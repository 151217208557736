import {
  t,
  plural
} from "@lingui/macro"

import http from "~/http"
import { useForm, useField } from "vee-validate"

export default {
  setup() {
    const {
      errors,
      handleSubmit,
      isSubmitting
    } = useForm({
      initialValues: {
        email: null
      }
    })

    const { value: emailRef } = useField("email")
    const onSubmit = handleSubmit(
      async (values, { setErrors, resetForm }) => {
        try {
          await http.post("recovery", values)
          resetForm()

          // TODO: Yet another one.
          setErrors([
            t`We have sent an email to your email address
              with instructions to reset your password.`
          ])
        }
        catch (error) {
          setErrors([
            error
              .response
              .data
          ])
        }
      }
    )

    return {
      emailRef,
      errors,
      onSubmit
    }
  },
  render() {
    const {
      errors,
      onSubmit,
    } = this

    return (
      <Fragment>
        <form onSubmit={onSubmit}>
          <div class="text-center mb-4">
            <div class="mb-4 h4 mt-2">
              {t`Reset password`}
            </div>
            <div class="text-muted">
              {t`Enter the email associated with your account and
                 we'll send you an email with instructions to reset
                 your password.`}
            </div>
          </div>
          <hr/>
          {errors[0] && (
            <div class={[
              `alert-${
                errors[0] && !this.emailRef
                  ? "success"
                  : "danger"
              }`,
              "alert px-3 py-2 mb-3 mt-4"
            ]}>
              {errors[0]}
            </div>
          )}
          <div class="mb-4">
            <label>
              {t`Email`}:
            </label>
            <input
              v-model={this.emailRef}
              placeholder=""
              type="email"
            />
          </div>
          <button class="btn-primary btn">
            {t`Continue`}
          </button>
        </form>
        <div class="mt-4">
          <hr/>
          <RouterLink to="/auth/login">
            {t`Already have an account?`}
          </RouterLink>
        </div>
      </Fragment>
    )
  }
}
