import {
  computed
} from "vue"

import queryString from "query-string"

import {
  t,
  plural
} from "@lingui/macro"

import http from "~/http"
import { useForm, useField } from "vee-validate"

const {
  VUE_APP_SUPPORTS_REGISTER
} = process.env

export default {
  setup() {
    const {
      errors,
      handleSubmit,
      isSubmitting
    } = useForm({
      initialValues: {
        login: null,
        password: null
      }
    })

    const { value: loginRef } = useField("login")
    const { value: passwordRef } = useField("password")

    const onSubmit = handleSubmit(
      async (values, { setErrors }) => {
        try {
          const { data } = await http.post("auth", values)
          localStorage.accessToken = data
          location.replace("/")
        }
        catch (error) {
          setErrors([
            error
              .response
              .data
          ])
        }
      }
    )

    const isDisabled = computed(
      () => ! (loginRef.value && passwordRef.value) || isSubmitting.value
    )

    return {
      errors,
      isDisabled,
      loginRef,
      onSubmit,
      passwordRef
    }
  },
  render() {
    const {
      errors,
      isDisabled,
      onSubmit
    } = this

    const {
      message
    } = queryString.parse(location.search)

    return (
      <Fragment>
        <form onSubmit={onSubmit}>
          <div class="text-center mb-4">
            <div class="mb-4 h4 mt-2">
              {t`Welcome!`}
            </div>
            <div class="text-muted">
              {t`Please sign in to account with your login and password.`}
            </div>
          </div>
          <hr/>
          {(message || errors[0]) && (
            <div
              class={`alert-${
                message && !errors[0]
                  ? "secondary"
                  : "danger"
              }
              alert px-3 py-2 mb-3 mt-4`}
            >
              {errors[0] || message}
            </div>
          )}
          <div class="mb-2">
            <label>
              {VUE_APP_SUPPORTS_REGISTER
                ? t`Email (or Login)`
                : t`Login`}:
            </label>
            <input
              v-model={this.loginRef}
              placeholder=""
              type="login"
            />
          </div>
          <div class="mb-4">
            <label>
              {t`Password`}:
              <RouterLink class="d-inline-block fw-normal float-end" to="/auth/reset">
                {t`Forgot password?`}
              </RouterLink>
            </label>
            <input
              v-model={this.passwordRef}
              placeholder=""
              type="password"
            />
          </div>
          <button class="btn-primary btn" disabled={isDisabled}>
            {t`Sign In`}
          </button>
        </form>
        <div class="mt-4">
          <hr/>
          {t`Need an account?`}
          &nbsp;
          <RouterLink to="/auth/register">
            {t`Register`}
          </RouterLink>
        </div>
      </Fragment>
    )
  }
}
