import {
  defineStore,
  createPinia
} from "pinia"

import http from "~/http"

export const useStore =
  defineStore({
    id: "mainStore",
    state: () => ({
      accounts: null,
      modal: null,
      wallets: null
    })
  })

export const useEquipmentStore =
  defineStore({
    id: "equipmentStore",
    state: () => ({
      equipment: null
    }),
    actions: {
      async fetch(options = {}) {
        const {
          page
        } = options

        const { data } = await http.get("equipment", {
          params: {
            page
          }
        })

        this.equipment = data
      },

      create(data) {
        return http({
          method: "post",
          url: "equipment",
          data
        })
      },

      update(id, data) {
        return http({
          method: "put",
          url: `equipment/${id}`,
          data
        })
      },

      delete(id) {
        return http.delete(`equipment/${id}`)
      }
    }
  })

export default createPinia()
